import React from "react";
import { Link } from 'react-router-dom'
import logo from '../img/logo.jpg'

export default function Navbar() {
  return (
    <nav className="navbar navbar-default">
      <Link className="navbar-brand" to="/">
        <img
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt=""
        />
        Spatialardhi
      </Link>
    </nav>
  );
}
