import React, { Component } from 'react';
import Footer from '../components/Footer';
import Merchant from '../components/Merchant';
import Navbar from '../components/Navbar';

class SingleMerchant extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <Navbar />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <Merchant />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div
                            style={{ background: "#FF00FF", color: "white" }}
                            className="col-md-12 col-sm-12 col-lg-12"
                        >
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleMerchant;