import React, { lazy, Suspense } from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
// import ProductReview from './review';
const ProductReview = lazy(() => import('./review'));
function ProductsReviews(id) {
	const [ products, setProducts ] = useState([]);

	useEffect(() => {
		axios.get('https://www.spatialardhi.com/estate/productreviews/?format=json').then((res) => {
			setProducts(res.data);
			console.log('productreview',res.data);
		});
	}, []);

	return (
		<div className="review">
			<h1>Product Reviews </h1>
			<Suspense fallback={<div>Loading...</div>}>
				{products.map((product) => <ProductReview data={product} key={product.id} />)}
			</Suspense>
		</div>
	);
}

export default ProductsReviews;
