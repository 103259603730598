import React, { useState, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
const Reviews = lazy(() => import('./reviews'));
function MerchantReviews(id) {
	const [ merchantReviews, setMerchantReviews ] = useState([]);

	useEffect(() => {
		let mounted = true;
		axios.get('https://www.spatialardhi.com/estate/reviews/?format=json').then((res) => {
			// setBlogPostReplies(res.data);
			let Bid=id.id;
			if(Array.isArray(res.data))
			{
				let filteredData = res.data.filter(res => { if (res.merchant.id == Bid) return res });
				setMerchantReviews(filteredData);
				// console.log(filteredData);
			}
		});
		return () => {
			mounted = false;
		};
		// 
	}, []);

	return (
		<div className="review">
			<h5>Merchant reviews from buyer & Merchant Response from buyer</h5>
			<Suspense fallback={<div>Loading...</div>}>
				{merchantReviews.map((review) => <Reviews data={review} key={review.id} />)}
			</Suspense>
		</div>
	);
}

export default MerchantReviews;
