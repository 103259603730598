import React from "react";
import "./index.css";
import Route from "./Routes";
function App() {

  return (
    <div>
      <Route />
    </div>
  );
}

export default App;
