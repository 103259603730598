import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactStars from "react-rating-stars-component";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ProductsReviews from "./ProductsReviews";
import MerchantReviews from "./MerchantReviews";
export default function Product({ id }) {
  const [product, setProduct] = useState([]);
  const [prodRev, setProdRev] = useState([]);
  const [mercRev, SetMercRev] = useState([]);

  //model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [formdata, setFormdata] = useState({
    avatar: null,
    price: "",
    description: "",
    name: "",
    merchant_email: "",
    merchant_phone_number: "",
  });

  const [message, setMessage] = useState('')

  useEffect(() => {
    fetch(
      `https://www.spatialardhi.com/estate/merchant/products/${id}?format=json`
    )
      .then((res) => res.json())
      .then((result) => {
        setProduct(result);
      });
    fetch(
      `https://www.spatialardhi.com/estate/merchant/reviews/${id}?format=json`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        SetMercRev(result);
      });
    fetch(
      `https://www.spatialardhi.com/estate/product/reviews/${id}?format=json`
    )
      .then((res) => res.json())
      .then((result) => {
        setProdRev(result);
      });
  }, [id]);


  function deleteComment(id) {
    console.log(id)
    axios
      .delete(
        `https://www.spatialardhi.com/estate/merchant/reviews/${id}?format=json`
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  function hanldeSubmit(e) {
    e.preventDefault();

    const fn = new FormData();
    fn.append("user_comments", formdata.userComments);
    fn.append("stars", formdata.stars);
    fn.append("merchant_response", formdata.merchantResponse);
    fn.append("transaction_approved", formdata.transApproved);
    fn.append("rate_experience", formdata.rateExp);
    fn.append("merchant", formdata.merchant_email);
    fn.append("product", formdata.product);

    axios
      .post("https://www.spatialardhi.com/estate/reviews/?format=json", fn)
      .then((res) => {

        setMessage('Successfully added...')
      })
      .catch((err) => setMessage('Something wrong...'));
  }


  function onchange({ target }) {
    const value = target.type === "file" ? target.files[0] : target.value;
    setFormdata((prevState) => ({ ...prevState, [target.name]: value }));
  }


  return (
    <>
      <div className="row mt-2">
        {product === undefined || product.length === 0 ? (
          <div>Loading...</div>
        ) : (
            product.map((prod) => {
              return (
                <div className="card product-list" style={{ width: "18rem" }}>
                  <TransformWrapper>
                    <TransformComponent>
                      <img width="100%" src={prod.photo_url} alt={prod.name} />
                    </TransformComponent>
                  </TransformWrapper>
                  <div className="card-body " style={{ color: "cyan" }}>
                    <h5 className="card-title">Name : {prod.name}</h5>
                    <h3 className="card-title">Price : {prod.price}</h3>
                    <p className="card-text">Desription : {prod.description}</p>
                    <p className="card-text">Email : {prod.merchant_email}</p>
                    <p className="card-text">
                      No : {prod.merchant_phone_number}
                    </p>
                    <p>Review : {prodRev.length}</p>
                  </div>
                  {/* <button className="dlt-btn">
                      Edit
                  </button>
                    <br />
                    <button className="dlt-btn" onClick={() => getid(prod.id)}>
                      Delete
                  </button> */}
                </div>
              );
            })
          )}
      </div>
      <div className="row mt-4">
        <div className="col-6">
          {/* <Button variant="primary" onClick={handleShow}>
            Add Review
          </Button> */}
          <MerchantReviews id={id} />
          </div>
        <div className="col-6">
          {/* <Button variant="primary" onClick={handleShow}>
            Add Review
          </Button> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Merchant Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={hanldeSubmit}>
                <Form.Group>
                  <Form.Label>User Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="userComments"
                    value={formdata.userComments}
                    onChange={onchange}
                    placeholder="User Comments"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Stars</Form.Label>
                  <Form.Control
                    as="select"
                    name="stars"
                    value={formdata.stars}
                    onChange={onchange}>
                    <option value="5 Stars">5 Stars</option>
                    <option>4 Stars</option>
                    <option>3 Stars</option>
                    <option>2 Stars</option>
                    <option>1 Stars</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Merchant Response</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="merchantResponse"
                    value={formdata.merchantResponse}
                    onChange={onchange}
                    placeholder="Merchant Response"
                  />
                </Form.Group>

                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox name="transApproved" value={formdata.transApproved} aria-label="Transaction Approved" />
                  </InputGroup.Prepend>
                  <label>Transaction Approved</label>
                </InputGroup>

                <Form.Group>
                  <Form.Label>Rate Experience</Form.Label>
                  <Form.Control
                    as="select"
                    name="rateExp"
                    value={formdata.rateExp}
                    onChange={onchange}>
                    <option value="5 Stars">5 Stars</option>
                    <option>4 Stars</option>
                    <option>3 Stars</option>
                    <option>2 Stars</option>
                    <option>1 Stars</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Merchant</Form.Label>
                  <Form.Control
                    as="select"
                    name="merchant"
                    value={formdata.merchant}
                    onChange={onchange}>
                    <option></option>
                    <option>islamabad traders</option>
                    <option>Karachi Traders</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Product</Form.Label>
                  <Form.Control
                    as="select"
                    name="product"
                    value={formdata.product}
                    onChange={onchange}>
                    <option>web4mec@gmail.com</option>
                    <option>machine@gmail.com</option>
                  </Form.Control>
                </Form.Group>

                <p>{message}</p>
                <Button variant="primary" type="submit">
                  Save
                    </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <ProductsReviews id={id} />
        </div>
      </div>
    </>
  );
}
