import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Replies from './replies';
function BlogPostReplies(id) {
	const [blogPostReplies, setBlogPostReplies] = useState([]);
	useEffect(() => {
	
		let mounted = true;
		axios.get('https://www.spatialardhi.com/estate/replies/?format=json').then((res) => {
			
			let Bid=id.id;		
			if(Array.isArray(res.data))
			{
				let filteredData = res.data.filter(res => { if (res.merchant.id == Bid) return res });
				setBlogPostReplies(filteredData);
			}
		});
		return () => {
			mounted = false;
		};
		// 
	}, []);
	return (
		<div className="review">
			<h1>Blog Post Replies</h1>
			{blogPostReplies.map((reply) => <Replies data={reply} key={reply.id} />)}
		</div>
	);
}

export default BlogPostReplies;
