import React from "react";

const Privacy = () => {
    return (
        <div className="container">
            <center><p><strong><u>Privacy Policy:</u></strong></p>
                <p><a href="http://www.spatialardhi.com"><strong>www.spatialardhi.com</strong></a></p>
                <p><strong><u>Last Updated on 07-12-2020</u></strong></p></center>
            <p>This privacy policy forms part of and must be read in conjunction with this website`s Terms and Conditions. SPATIAL AND ARDHI (K) LTD is e commerce website for buyers and merchants for online selling and buying, for further information please read from &ldquo;About&rdquo; section of our website <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>.</p>
            <p><strong>Our Privacy:</strong></p>
            <p>This Website is owned and controlled by <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>. &nbsp;Also be referred as &ldquo;We&rdquo;, &ldquo;Us&rdquo; or &ldquo;Our&rdquo; in this Privacy Policy. We respects the privacy of visitors/surfers to its Website and advises all visitors/surfers and clients to read and understand our privacy policy, and to check it periodically for any updates or revisions.</p>
            <p>We&nbsp;reserve&nbsp;the&nbsp;right&nbsp;to&nbsp;change&nbsp;this&nbsp;Privacy&nbsp;Policy&nbsp;at&nbsp;any&nbsp;time. It is our aim through this PRIVACY POLICY (&ldquo;Policy&rdquo;) to explain what information we collect about you and why we collect that information. We also want to make sure you understand how we use and share your information and the choices available to you. Our goal is to earn and keep your trust as you visit our websites and applications about our specific services.</p>
            <p>This Policy describes how we treat personal information on websites (&ldquo;sites&rdquo;), mobile or social media applications (&ldquo;apps&rdquo;), and any other services where we include an authorized link or reference to this Policy (together, the &ldquo;Services&rdquo;).</p>
            <p>When&nbsp;you&nbsp;visit&nbsp;our&nbsp;website (&ldquo;Site&rdquo;)&nbsp;and&nbsp;use&nbsp;our&nbsp;services,&nbsp;you&nbsp;trust&nbsp;us&nbsp;with by giving us with your&nbsp;personal&nbsp;information. This website takes your privacy very serious and in this privacy policy, we will describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have concerning it.</p>
            <p>Your use of our apps and sites indicates you agree to our collection, use, and sharing of your information as set forth in this Policy.</p>
            <p><strong>IF YOU DO NOT AGREE AND ACCEPT THE PRIVACY POLICY OF THIS WEBSITE AND OUR SIMILAR TECHNOLOGIES, PLEASE WE RECOMMEND YOU TO NOT USE THIS SITE.</strong></p>
            <p><strong><u>What </u></strong><strong><u>information you shared with us (</u></strong><a href="http://www.spatialardhi.com"><strong>www.spatialardhi.com</strong></a><strong>)?</strong></p>
            <p><strong><u>&nbsp;</u></strong></p>
            <p>While using our Site <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name. Some of the information which we collect from you consists of;</p>
            <ol>
                <li><strong>We collect identifiers and contact information:</strong></li>
            </ol>
            <p>We may&nbsp;require information such as your name, email address, birthday, nationality and &nbsp; gender. We collection your information which is required when you sign up for the site, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as well as the information you choose to share.</p>
            <p>We may collect your name and street address if you register on our site or apps. We may also collect your phone number or email address. We also collect online identifiers. For example, if you engage with us through a social media site, we may collect your social media user identifier.</p>
            <p>We collects and processes information about visitors to its Website to conduct its business, to inform and make available services that may be of interest to you and for the development of web statistics. The information provided by you can be used to contact you when necessary.</p>
            <p>&nbsp;</p>
            <ol>
                <li><strong>We collect payment information.</strong></li>
            </ol>
            <p>We may require your banking information for billing purposes (like buying and selling purpose). This also includes your credit card number.</p>
            <ul>
                <li><strong>We collect information you submit or otherwise provide or authorize us to collect.</strong></li>
            </ul>
            <p>We collect the information you post in a public space on our site. This includes comments and videos or photos. We may also collect any other information you may provide to us or authorize us to collect. This may include information about your physical activities or your nutrition preferences.</p>
            <ol>
                <li><strong>We collect demographic information and information about your interests.</strong></li>
            </ol>
            <p>We may collect information such as your gender and age, household or lifestyle information, and your ZIP Code.</p>
            <ol>
                <li><strong>We collect internet and electronic network activity information, such as device and usage information.</strong></li>
            </ol>
            <p>We may collect information about the browser you are using, and we may look at what site you came from, or what site you visit when you leave us. We may also collect information about your operating system, Internet Service Provider (ISP) information and IP addresses and other device identifiers, such as mobile device identifiers. In addition, we may collect information about your use of our Services, including through use of tracking technologies such as cookies, pixel tags, and Flash Cookies.</p>
            <ol>
                <li><strong>We collect geo-location information.</strong></li>
            </ol>
            <p>Using technologies such as GPS or Wi-Fi, we may collect information about your location. For example, if you use our mobile apps, we may access your device&rsquo;s location information to provide you location-enabled features.</p>
            <ul>
                <li><strong>We collect information about third parties.</strong></li>
            </ul>
            <p>From time to time, we may allow you to send an email or message to a friend through one of our sites or apps. If you choose to do this, we will need that person&rsquo;s name and email address.</p>
            <p>&nbsp;</p>
            <p><strong>Information&nbsp;Collected&nbsp;From&nbsp;Other&nbsp;Sources</strong>:</p>
            <p>We&nbsp;may&nbsp;obtain&nbsp;information&nbsp;about&nbsp;you&nbsp;from&nbsp;other&nbsp;sources,&nbsp;such&nbsp;as&nbsp;public&nbsp;databases,&nbsp;joint marketing&nbsp;partners,&nbsp;social&nbsp;media&nbsp;platforms&nbsp;(such&nbsp;as&nbsp;Facebook),&nbsp;as&nbsp;well&nbsp;as&nbsp;from&nbsp;other&nbsp;third Parties.</p>
            <p>If&nbsp;you&nbsp;have&nbsp;chosen&nbsp;to&nbsp;subscribe&nbsp;to&nbsp;our&nbsp;newsletter,&nbsp;your&nbsp;first&nbsp;name,&nbsp;last&nbsp;name&nbsp;and&nbsp;e-mail&nbsp;address will be shared with our newsletter provider. This is to keep updated with information and offers for marketing purposes.</p>
            <p><strong><u>How do we collect information?</u></strong></p>
            <p><strong><u>&nbsp;</u></strong></p>
            <ol>
                <li><strong>We collect information directly from you.</strong></li>
            </ol>
            <p>We collect information from you when you:</p>
            <ul>
                <li>Make a purchase with us;</li>
                <li>Register on one of our sites or apps;</li>
                <li>Join our loyalty programs;</li>
                <li>Apply for a job;</li>
                <li>Sign up for our email newsletters and other notifications;</li>
                <li>Participate in promotions such as sweepstakes or contests or protests;</li>
                <li>Fill out a survey;</li>
                <li>Contact us, such as through our sites and apps or otherwise communicating with our Consumer Relations team; and otherwise provide it to us or authorize us to collect it.</li>
            </ul>
            <ol>
                <li><strong>We collect information from you through tracking tools.</strong></li>
            </ol>
            <p>We may use tracking tools like browser cookies and mobile device IDs to collect information when you use the Services or join any service (buying or selling). We do this on our websites, in emails that we send to you, and in media advertising. We collect information about usage and browser information over time.<em>.</em></p>
            <ul>
                <li><strong>Third parties may also share information about you with us.</strong></li>
            </ul>
            <p>Our business partners may give us information about you. We may receive information from commercial sources such as companies who compile information about shoppers and their preferences. Social media platforms may also give us information about you, and we may get information about your interactions with our ads on third-party sites.</p>
            <p>Some information provided by third parties may be aggregated or otherwise de-identified so that it does not identify you. Aggregated and de-identified data are not subject to this Privacy Policy.</p>
            <ol>
                <li><strong>We combine information from different sources.</strong></li>
            </ol>
            <p>We may combine information we have collected offline with information we collect online. We combine information we have collected across third-party sites, including social media sites. We combine information across devices such as computers and mobile devices. We may also combine information from third parties with information we already have. We may use this information to generate inferences about you and for the other purposes described in this Privacy Policy.</p>
            <p>&nbsp;</p>
            <p><strong><u>How Do We Use the Information We Collect?</u></strong></p>
            <p><u>&nbsp;</u></p>
            <ol>
                <li><strong>We use information to help us effectively provide you products and services.</strong></li>
            </ol>
            <p>We use your information to respond to your questions or enable you to participate in our programs. This may include sweepstakes or contests. We also may use your information to process your registration with sites and apps. We may use your friends and family&rsquo;s&rsquo; email addresses to send them information that you requested we send them. We also use your information to process orders for you. We may also contact you about products and services you have requested, or if you have won a prize or promotion.</p>
            <ol>
                <li><strong>We use information to improve our products and services.</strong></li>
            </ol>
            <p>One of our goals is that you have a positive experience with our sites, apps, and products. Therefore, we may use your information to improve your experience with our sites, apps, and products. We may use information you provide or that third-party partners give us to personalize your experience.</p>
            <ol>
                <li><strong>We use information for security purposes.</strong></li>
            </ol>
            <p>We may use your information to help protect you, our sites and apps, our company, our customers, and our consumers.</p>
            <ol>
                <li><strong>We use information for marketing purposes.</strong></li>
            </ol>
            <p>We might send you information about products, offers, features, or updates. We might also use your information to serve you ads about products and offers. Those ads may appear on third-party platforms which may include social media. These might be our own offers or products, or third-party offers or products we think you might find interesting. We may provide you with information through email or push notification.</p>
            <ol>
                <li><strong>We use information to get feedback from you about how we&rsquo;re doing and for other transactional purposes.</strong></li>
            </ol>
            <p>We may contact you about this Policy or the Terms of Use posted to the sites or apps you are using (includes after buying or selling on this website). We may also communicate with you about a job application or your questions about our products or promotions. We use information as the law allows us or as we may notify you.</p>
            <p>&nbsp;</p>
            <p><strong><u>How Do We Share Your Information?</u></strong></p>
            <p>&nbsp;</p>
            <ol>
                <li><strong>We share information within the </strong><strong>SPATIAL AND ARDHI (K) LTD</strong><strong> family of companies and brands.</strong></li>
            </ol>
            <p>This includes current and future SPATIAL AND ARDHI (K) LTD subsidiaries, affiliates, and joint venture partners. We may also share information with third parties with which we have an ownership interest or commercial relationship, such as companies whose products we distribute or whose products we think you might find interesting.</p>
            <ol start="2">
                <li><strong>We share information with our business partners and third-party vendors.</strong></li>
            </ol>
            <p>We may share information with joint promotion partners. If you register for a joint promotion, your information may be collected by both us and the third party. This might include retailers. Your information will be used by us as described in this Policy or by promotion partners as described in their policies. We may also share information with companies who serve as our vendors or third parties who perform services on our behalf. They may help us deliver products or services to you. We may share information with companies who operate our sites and apps or who run our promotions.</p>
            <ol start="3">
                <li><strong>We will share information if we think we must in order to comply with the law or to protect our company.</strong></li>
            </ol>
            <p>We will share information to respond to a court order or subpoena. We may share information if a government agency or investigatory body requests it. This also includes Kenya and other states law enforcement or regulatory authorities. We may also share information to help protect you, our sites and apps, our company, our customers, and our consumers. We may also share information if we are investigating potential fraud.</p>
            <ol start="4">
                <li><strong>Third parties may collect information when you use the Services.</strong></li>
            </ol>
            <p>Third parties that provide advertising, content, and/or functionality to our Services may collect information from these Services through tracking tools like browser cookies and mobile device IDs. For example, third-party advertisers may have a presence on some of our Services, and this information may be collected over time and combined with information collected across different websites and online services.&nbsp;</p>
            <ol start="5">
                <li><strong>We may share information with any successor to all or part of our business.</strong></li>
            </ol>
            <p>If part of our business is sold, or is involved in a merger, consolidation, asset sale, or in the unlikely event of bankruptcy, we may include your information as part of that transaction. If there are additional reasons we may share information, we will describe those to you.</p>
            <p>&nbsp;</p>
            <p><strong><u>You Have Certain Choices about How We Use Your Information</u></strong></p>
            <p><strong><u>&nbsp;</u></strong></p>
            <ul>
                <li><strong>You can opt out of email marketing.</strong></li>
            </ul>
            <p>To stop receiving promotional emails from us, follow the instructions in any promotional email message you get from us. If you opt out of getting marketing email messages, you may continue to receive non-marketing email messages from us where permitted by law.</p>
            <ul>
                <li><strong>You can control cookies and tracking tools.</strong></li>
            </ul>
            <p><em>Do-Not-Track Signals and Similar Mechanisms,</em>&nbsp;Some web browsers transmit "do-not-track" signals to websites. Because the standards for these signals are still under development, we currently do not take action in response to these signals.</p>
            <ul>
                <li><strong>You can control tools on your mobile devices. </strong></li>
            </ul>
            <p>You can turn off the GPS locator or push notifications on your phone. To modify the push notifications you get from our app, you can alter your preferences in your profile.</p>
            <ul>
                <li><strong>You may have other choices under state laws.</strong></li>
            </ul>
            <p>Depending on where you live, you may have certain additional rights, such as the right to request access to your personal information or to request deletion of your personal information.</p>
            <p>Requests should be submitted via email or in the &ldquo;Contact Us&rdquo; section of this Website. These rights are not absolute, and we will respond to all requests we receive in accordance with applicable laws. These Rights section below provides additional information for the users.</p>
            <ul>
                <li><strong>Children&rsquo;s Privacy.</strong></li>
            </ul>
            <p>It is not our intention to collect personal information from children under age 13. If you are a parent or legal guardian and think that your child under 13 has given us information, you can submit your information and request via email or on the &ldquo;Contact Us&rdquo; section of this website, and we will take appropriate steps.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Security Measures</strong></li>
            </ul>
            <p>We use various technical and organizational mechanisms to secure our websites and apps. You should understand that no data storage system or transmission of data over the Internet or any other public network can be guaranteed to be perfectly secure.</p>
            <ul>
                <li><strong>Our Services Are Intended for a Global Audience</strong></li>
            </ul>
            <p>If you live anywhere in any state of the world and choose to use this site or app, you understand that it is at your own risk. You also understand that your information will be sent to and stored in the Kenya. The Laws of the land (Kenya) may not afford the same level of protection as laws in your own country.</p>
            <ul>
                <li><strong>We May Link to Other Sites or Apps or Have Third Party Services and Tools on Our Platform We Don't Control</strong></li>
            </ul>
            <p>We may link to or allow you to access the Services from third-party websites or platforms. Our sites and apps may also include third-party content that collects information. Third party content may include tools like cookies and tracking technologies. These third parties may use your information for their own purposes. This may include behavioral advertising and analytic purposes. We do not control these third parties or their use of these technologies, so please read their privacy policies carefully.</p>
            <p><strong><u>We May Update and change this Policy</u></strong></p>
            <p>&nbsp;</p>
            <p>From time to time, we may change this Policy. When we do, we will notify you of any material changes as required by law. This will include posting an updated copy here and updating the &ldquo;Last Updated&rdquo; date. Please check periodically for updates.</p>
            <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy.</p>
            <p><strong><u>Copyrights: We are protecting Other People's Intellectual Property Rights:</u></strong></p>
            <p>We respect other people's rights, and expect you to do the same. You will not post content, product or take any action <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> that infringes or violates someone else's rights or otherwise violates the law. We can remove any content, product or information you post on <a href="http://www.spatialardhi.com">www.spatialardhi.com</a><strong>.</strong>if we believe that it violates this Privacy Policy or the Terms of Use on this Site. You will not use our copyrights or any confusingly similar marks, without our written permission. If you collect information from our Users, you will: obtain their consent, make it clear you (and not <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>) are the one collecting their information, and post a privacy policy explaining what information you collect and how you will use it. You will not post anyone's identification documents or sensitive financial information on <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>. You will not tag our fellow Users or send email invitations to non-users without their consent.</p>
            <p><strong><u>Contact Us:</u></strong></p>
            <p>&nbsp;If you have any questions about this Privacy Policy of our website, please contact us at <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>.</p>
        </div>
    );
};

export default Privacy;
