import React, { Component } from 'react';

class MerchantImage extends Component {
    constructor(props) {
        super(props);
        this.state = { image: null }
    }
    componentDidMount() {
        const merchantImageUrl = this.props.image;
        fetch(merchantImageUrl)
            .then((response) => response.json())
            .then(
                (data) => { this.setState({ image: data.image }); }
            );
    }
    render() {
        if (!this.state.image || this.state.image.length === 0) return (<img alt="Merchant Profile" src="https://images.pexels.com/photos/1662159/pexels-photo-1662159.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" className="img-fluid" />);
        else
            return (<img src={this.state.image} alt="Merchant Profile" className="img-fluid" />)
    }
}

export default MerchantImage;