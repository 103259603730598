
import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";

export default function map({position}) {
    console.log(position)
  return (
    <div>
      {position.length===0 ? <div>loading...</div> :<Map center={[position.latitude, position.longtitude]} zoom={12}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[position.latitude, position.longtitude]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </Map>}
    </div>
  );
}
