import React from "react";
import SearchForm from './SearchForm';
import MerchantList from './MerchantList';

export default function Search() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 col-sm-3 col-lg-3 mt-2">
          <SearchForm />
        </div>
        <div className="col-sm-12 col-md-9 col-lg-9">
          <div className="row">
            <MerchantList />
          </div>
        </div></div>
    </div>
  );
}


