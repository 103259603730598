import React from "react";

const Terms = () => {
    return (
        <div className="container">
            <center><p><strong><u>TERMS OF USE</u></strong></p>
                <p><a href="http://www.spatialardhi.com">www.spatialardhi.com</a></p>
                <p><strong><u>Last Updated: 12/08/2020</u></strong></p></center>
            <p><strong>Introduction:</strong></p>
            <p>Welcome to <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>&nbsp; also hereby known as &ldquo;we", "us" or "SPATIAL AND ARDHI (K) LTD ". We are an online marketplace where any Merchant/Seller can sell its products to the buyer (Worldwide) and any buyer from worldwide can purchase any service or product from online Merchants/Sellers available on this website to and these are the terms of use for this website, governing your access and use of <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> along with its related sub-domains, sites, mobile app, services and tools (the "Site") if any. By using the Site, you hereby accept these terms of use of this website (including the linked information herein) and represent that you agree to comply with these terms of use (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site.</p>
            <p>The Site reserves the right to change, modify, add, or remove portions of these Terms of Use at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms of Use regularly for updates. Your continued use of the Site following the posting of changes to Terms of use constitutes your acceptance of those changes.</p>
            <p>Please read these Terms of Use carefully before using our website ("known as Terms"). These Terms govern and apply to your access and use of the communication platform offered by <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>, SPATIAL AND ARDHI (K) LTD services available through our Network via the <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> website. &ldquo;SPATIAL AND ARDHI (K) LTD&rdquo; and SPATIAL AND ARDHI (K) LTD "Service(s)") and any information, text, graphics, products, photos or other materials uploaded, downloaded or appearing on the Service (collectively referred to as "Product or Content"). By accessing or using the Service, you agree to comply with and to be bound by all of the terms and conditions described here in these Terms. If you do not agree to all of these terms and conditions, you are not authorized to use the Service. The terms &ldquo;You", "Your,&rdquo; and &ldquo;Yourself&rdquo; refer to the individual user (Both buyer or Merchant/Seller) of the Service.</p>
            <p>You hereby certify and acknowledge that you are;</p>
            <ul>
                <li>Over the age of eighteen (Major) (18) or have the legal ability to consent to Therapy or have your Parent/Guardian consent to Therapy and consultation, and</li>
                <li>Physically located or are a resident of the State or Country you have chosen as your current residency when creating your Account. You agree to provide &ldquo;Contact Information&rdquo; (your personal contact and/or a close family member/relation) to your Merchant/Seller to act as an information source to be used in case of emergency. You acknowledge that your ability to access and use the Service is conditioned upon the truthfulness of the information you provide regarding your age, residence and Contact Information and that the Providers you access are relying upon this certification in order to interact with you and provide the Services.</li>
            </ul>
            <p>These public terms written for the users, end users and for the Merchants/Sellers who uses SPATIAL AND ARDHI (K) LTD website.</p>
            <p><strong>Content or Products of the Site:</strong></p>
            <p>The content or products of the website should not be considered as final choice, as SPATIAL AND ARDHI (K) LTD is providing variety of products according to the choice of the users and between your service provider (Merchant/Seller) and you (Buyer and may also be Merchant/Seller) whether it is one on one or group user.</p>
            <p>None of our site product content represents and gives warranty about any particular availability or reference as we do not endorse any, products and procedures but providing middle platform between buyers and Merchants/Sellers.</p>
            <p><strong>Account:</strong></p>
            <p>Individual user account enrollment (&ldquo;Account&rdquo;) is compulsory to access our services by providing necessary information. You agree that you will not create multiple accounts by using your personal details and also agrees that you will not use other`s details without their permission for your personal use. Know your customers documentation &amp; education certificates &amp; business incorporations must be provided partially or in combination.</p>
            <p>For your use of our services and for services provider (Merchant/Seller) you must provide us with,</p>
            <ol>
                <li>Provide us true, accurate, current and complete information about you as prompted by our Account enrollment form or any other prescribed form. And</li>
                <li>Each time you log on, maintain and promptly update such Account information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or SPATIAL AND ARDHI (K) LTD has reasonable grounds to suspect that such Account information is untrue, inaccurate, not current or incomplete, SPATIAL AND ARDHI (K) LTD reserves the right to transition your order to another Merchant/Seller outside of SPATIAL AND ARDHI (K) LTD and refuse any future request(s) to use the SPATIAL AND ARDHI (K) LTD Services. You shall at all times remain solely responsible for maintaining the confidentiality of your Account password and username (if any) and any other security information related to your Account. SPATIAL AND ARDHI (K) LTD will not be liable for any loss that you incur as a result of someone else accessing and using your Account, either with or without your knowledge.</li>
            </ol>
            <ul>
                <li>The users must know their customers documentation, education certificates and business incorporation, which are must to be provided partially or in combination at the time of the enrollment of an account.</li>
            </ul>
            <p><strong>Acceptable Use:</strong></p>
            <p>It is also known as <strong>acceptable usage policy</strong>&nbsp;or&nbsp;<strong>fair use policy. </strong>Please read this acceptable use policy before using <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> website.</p>
            <p>Services provided by us may only be used for lawful purposes. You agree to comply with the laws, rules and regulations in connection with your uses of our services. Any material or conduct that in our judgment violate our this policy in any manner, or use of unlawful, defamatory, libelous, fraudulent, obscene, harassing, threatening etc may results in suspension or termination of our services or removal of user`s account with or without giving any prior notice.</p>
            <p><strong>Payment:</strong></p>
            <p>SPATIAL AND ARDHI (K) LTD does not charge any user directly, nor do we help them in finding the right Merchant/Seller. You set up your order with your Merchant/Seller online on our portal and get charged by your Merchant/Seller or service provider.</p>
            <p>Any payment you make here for the use of the Services is used to compensate SPATIAL AND ARDHI (K) LTD for its software development, overhead, administrative services and other corporate costs and fees, including transaction fees for credit card usage.</p>
            <p><a href="http://www.spatialardhi.com">www.spatialardhi.com</a> does not hold itself out as your direct provider of online services for purchasing/selling as that is the role of your licensed, network accredited Provider. You agree to pay all fees and charges associated with your Account on a timely basis in accordance with the fee schedule (If any), and the terms and rates published by SPATIAL AND ARDHI (K) LTD from time to time. Such fees and charges (including any taxes and late fees, as applicable) may be charged to the credit card you provided during the initial setup of your Account. You agree to maintain valid credit card information in your Account. By providing SPATIAL AND ARDHI (K) LTD with credit card information you authorize SPATIAL AND ARDHI (K) LTD to bill and charge your credit card.</p>
            <p><strong>Merchants/Sellers and Buyers Rights:</strong></p>
            <p><strong>Merchants/Sellers:</strong></p>
            <p>SPATIAL AND ARDHI (K) LTD does not directly employ the Merchants/Sellers matched through the use of this Service. SPATIAL AND ARDHI (K) LTD created a modern, digital shopping international network of digitally and accredited Merchants/Sellers. The SPATIAL AND ARDHI (K) LTD Network only works with independent, licensed, insured and vetted professional Merchants/Sellers. SPATIAL AND ARDHI (K) LTD has business relationships with many of the largest Selling networks in the Country. SPATIAL AND ARDHI (K) LTD only arranges or provides their buyers with their relevant Merchants/Sellers.</p>
            <p>Merchants/Sellers may allow Buyers to purchase their services or products listed on the website of SPATIAL AND ARDHI (K) LTD and Merchants/Sellers may also use, take or allow the custom orders from the buyers. Each product or service which the Merchants/Seller sells to the buyer and successfully complete, accredits Merchants/Seller&rsquo;s account with revenue equal to 85% of the total purchase amount. SPATIAL AND ARDHI (K) LTD will accredit Merchants/Sellers once an order is completed and for further information about our payment, Merchants/Sellers can contact us or read our Payment Section.</p>
            <p>The Merchant's rating is calculated and based on the order reviews posted by Buyers or Business Account Team Members. A high rating allows Merchants to obtain advanced Merchant/Seller levels (see Levels below). In certain cases, exceedingly low ratings may lead to the suspension of the Merchant/Seller&rsquo;s account.</p>
            <p>For security concerns, we (SPATIAL AND ARDHI (K) LTD) may temporarily disable a Merchant&rsquo;s ability to withdraw revenue to prevent fraudulent or illicit activity. This may come as a result of security issues, improper behavior reported by other users, or associating multiple user accounts to a single withdrawal provider. Merchants are responsible for obtaining a general liability insurance policy with coverage amounts that are sufficient to cover all risks associated with the performance of their services/products.</p>
            <p>Services or products listed by the merchants or users may be removed by us from this Site for violations of these Terms of Service/use and/or our Community Standards, which may include (but are not limited to) the following violations and/or materials:</p>
            <ul>
                <li>Illegal or Fraudulent services or products.</li>
                <li>Copyright Infringement, Trademark Infringement, and violation of a third party's terms of service/use reported through our Intellectual Property Claims Policy.</li>
                <li>Adult oriented services or products, Pornographic, Inappropriate/Obscene</li>
                <li>Intentional copies of Services or Products listed</li>
                <li>Spam, nonsense, or violent or deceptive Services or Products listed</li>
                <li>Services or Products listed if found misleading to Buyers or others</li>
                <li>Reselling of regulated goods by using this platform.</li>
                <li>Services or products that extend beyond 30 days of service duration</li>
                <li>Exceedingly low quality products/services listed</li>
                <li>Promoting services or products listed on this platform through activities that are prohibited by any laws, regulations, and/or third parties' terms of service, as well as through any marketing activity that negatively affects our relationships with our users or partners.</li>
            </ul>
            <p>Products or services which are removed for violations mentioned above may result in the suspension of the Merchant&rsquo;s account and will not be eligible to restored or edited. Due to poor performance and/or user misconduct merchant&rsquo;s products/services may also be removed. The services or products of the merchant may also contain an approved Video uploaded through the products management tools available on SPATIAL AND ARDHI (K) LTD. Statements on the products Page that undermine or circumvent these Terms of Use/Service is prohibited. Eligible products in select Categories may set up product Packages to offer their services in structured formats with multiple price points for the selected product.</p>
            <p><strong>Buyer:</strong></p>
            <p>Buyer may not offer direct payments to Merchants using payment systems outside of the SPATIAL AND ARDHI (K) LTD platform. We retain the right to use all publicly published delivered works/products descriptions for our marketing and promotional purposes. Buyers may request a specific service/product from the Post a Request feature. Services requested on this website must be an allowed service on SPATIAL AND ARDHI (K) LTD.</p>
            <p>For making Payments through SPATIAL AND ARDHI (K) LTD <strong>7 % </strong>commission, fees or taxes will be charged according to the purchase price of each product or order.</p>
            <p>Order basics are included as;</p>
            <ul>
                <li>Once payment is confirmed, your (Buyer) order will be created and given a unique SPATIAL AND ARDHI (K) LTD order number (#FO).</li>
                <li>One placing order, Merchant/Sellers must deliver complete service or product ordered by the buyer by using delivery system.</li>
                <li>An Order will be marked as Complete after it is marked as Delivered and then accepted by a Buyer. An Order will be automatically marked as Complete if not accepted and no request for modification was submitted for ordered services or products within 3 days after the Order was marked as Delivered.</li>
                <li>We encourage our Buyers and Merchants/Sellers to try and settle conflicts amongst themselves. If for any reason this fails after using the Resolution Center or if you encounter non-permitted usage on the Site, users can contact our Customer Support department for assistance.</li>
                <li>Feedback reviews provided by Buyers while completing an Order are an essential part of our rating system. Reviews demonstrate the Buyer's overall experience of purchase with the Merchants/Sellers and their service or products. Buyers are encouraged to communicate to the Merchant/Seller any concerns experienced during their active order in regards to the service or product provided by the Merchant/Seller.</li>
                <li>Leaving a Buyer's feedback is a basic prerogative of a Buyer. Feedback reviews will not be removed unless there are clear violations of our Terms of Use/Service and/or our Community Standards</li>
                <li>Feedback comments given by Buyers are publicly displayed on a Merchant/Seller&rsquo;s services or products listed page.</li>
                <li>Users are allowed to leave reviews on Orders up to 10 days after an Order is marked as complete. No new reviews/comments may be added to an Order after 10 days of delivery.</li>
                <li>Merchants/Sellers may not solicit the removal of feedback reviews from their Buyers through mutual cancellations.</li>
            </ul>
            <p><strong>Intellectual Property Rights:</strong></p>
            <p>The <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> contains copyright material, trade names and marks and other proprietary information, including, but not limited to, text, software, photos and graphics, and may in future include video, graphics, music and sound ('Contents or Products'). The Content/Products are protected by copyright law, registered and unregistered trademarks, database rights and other intellectual property rights.</p>
            <p>You may download related information from the <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> for your own personal use only. Except as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission, translation, publication or commercial exploitation of downloaded material will be permitted without our express prior permission or that of the copyright owner/user. In the event of any permitted copying, redistribution or publication of copyright material, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made. You acknowledge that you do not acquire any ownership rights by downloading copyright material from SPATIAL AND ARDHI (K) LTD website.</p>
            <p><strong>Disclaimers:</strong></p>
            <p>The information contained in <a href="http://www.spatialardhi.com">www.spatialardhi.com</a> &nbsp;is for general information purposes only. The information is provided by SPATIAL AND ARDHI (K) LTD and while we Endeavour to keep the information up to date and correct, we make/agree no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own personal risk.</p>
            <p>We will not be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, uninterrupted errors or the use of this website.</p>
            <p>Every effort is made to keep the website up and running smoothly. However, SPATIAL AND ARDHI (K) LTD takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
            <h4>Claim against objectionable products:</h4>
            <p>We list thousands of products for sale offered by numerous Merchant/Sellers on the Site and host multiple comments on listings; it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, we operate on a "claim, review and takedown" basis. If you believe that any content on the Site is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of state or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (" objectionable content "), please notify us immediately by following by writing to us on our email&nbsp; We will make all practical endeavors to investigate and remove valid objectionable content complained about within a reasonable amount of time.</p>
            <p>Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of objectionable content party, instances of objection, proof of objection amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes.</p>
            <p><strong>Limitation of liability:</strong></p>
            <p>The products provided on this Website are intended to enable users to gain ready access to information and news about SPATIAL AND ARDHI (K) LTD&rsquo;s activities. SPATIAL AND ARDHI (K) LTD aims to keep the material up-to-date and accurate. While we use reasonable endeavors to verify the accuracy of any information we place on the Website, we make no warranties, whether express or implied in relation to such accuracy of products (Quality, Price, Availability, Change etc.)</p>
            <p>You acknowledge that we cannot guarantee and therefore shall not be in any way responsible for the security of the Website and any information provided to or taken from the SPATIAL AND ARDHI (K) LTD by you.</p>
            <p><strong>TO THE FULLEST EXTENT PERMITTED BY LAW, SPATIAL AND ARDHI (K) LTD ACCEPTS NO LIABILITY TO YOU(BUYER OR MERCHANTS/SELLER) OR ANY THIRD PARTY FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER ARISING FROM USE OR LOSS OF USE, DATA, OR PROFITS, WHETHER IN CONTRACT OR IN TORT, ARISING OUT OF OR IN CONNECTION WITH THE VIEWING, USE, RELIANCE ON OR PERFORMANCE OF THE WEBSITE OR ITS CONTENT, WHETHER DUE TO INACCURACY, ERROR, OMISSION OR ANY OTHER CAUSE AND WHETHER ON THE PART OF SPATIAL AND ARDHI (K) LTD, ITS REPRESENTATIVES, AGENTS, OR ANY OTHER PERSON. </strong></p>
            <p>We will not be held liable in contract, tort or otherwise if you incur loss or damage connecting to the Website through a third party&rsquo;s hypertext link.</p>
            <p><strong>Hyperlinks:</strong></p>
            <p>There may be cases in which our website when the SPATIAL AND ARDHI (K) LTD website provides hyperlinks to other locations or websites on the Internet. These hyperlinks lead to websites published or operated by third parties who are not affiliated with or in any way related to SPATIAL AND ARDHI (K) LTD.</p>
            <p>Hyperlinks have been included in our website to enhance your user experience and are presented for information purposes only. We Endeavour to select reputable websites and sources of information for your convenience.</p>
            <p>However, by providing hyperlinks to an external website or webpage, SPATIAL AND ARDHI (K) LTD shall not be deemed to endorse, recommend, approve, guarantee or introduce any third parties or the services/ products they provide on their websites, or to have any form of cooperation with such third parties and websites unless otherwise stated by SPATIAL AND ARDHI (K) LTD.</p>
            <p>We are not in any way responsible for the content of any externally linked website or webpage. You use or follow these links at your own risk and SPATIAL AND ARDHI (K) LTD is not responsible for any damages or losses incurred or suffered by you arising out of or in connection with your use of the link.</p>
            <p><strong>International Use:</strong></p>
            <p>The SPATIAL AND ARDHI (K) LTD Platform advises both its Client/Users (Buyers and Merchants/Sellers) be aware of and respect and comply with the local laws of any international based user. SPATIAL AND ARDHI (K) LTD is cognizant and respects the formal promulgation of the existing Kenyan privacy regulations.</p>
            <p><strong>Disclosure When You Sell, Transfer or Third Party Marketing of User&rsquo;s data:</strong></p>
            <p>&nbsp;As outlined in current Terms of Use, SPATIAL AND ARDHI (K) LTD does not sell or transfer Client data and any marketing efforts are focused solely on internal information updates to Clients.</p>
            <p><strong>Access to Collected Data:</strong> At SPATIAL AND ARDHI (K) LTD, you already have the ability to access your shared data (Your personal information, your emergency contact information and all your interactions with your Buyer/Merchants/Seller) and use it as you wish.</p>
            <p><strong>Clear Consent:</strong></p>
            <p>&nbsp;At SPATIAL AND ARDHI (K) LTD our Terms of Use are clear on what consent we seek with regards to your data and prior to your engagement with your Provider, you review and agree to a further &ldquo;informed consent&rdquo; process.</p>
            <p><strong>Notice/Audit:</strong> At SPATIAL AND ARDHI (K) LTD, we will provide our users notice of any data breach and we employ a full time security Officer, as well as engaging a third party security firm to periodically audit both or code and technology security as well as our HIPAA policies and procedures around data security.</p>
            <p><strong>Termination</strong></p>
            <p>We may suspend or terminate your access to the Service at any time, for any reason or for no reason at all. We have a right (but not the obligation) to refuse to provide access to the Service to any person, agency or organization at any time, for any reason or for no reason at all, in our sole discretion. SPATIAL AND ARDHI (K) LTD, reserves the right to change, suspend, or discontinue all or part of the Service, temporarily or permanently, without prior notice.</p>
            <p><strong>Disputes:</strong></p>
            <p>Any dispute or claim relating in any way to your use of the SPATIAL AND ARDHI (K) LTD Service will be resolved by binding confidential arbitration, rather than in court. The Federal Law of the land (Kenya) applies to these Terms.</p>
            <p>It is agreed between that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, SPATIAL AND ARDHI (K) LTD and you (User) each waive any right to a jury trial. It is further agreed that you may not bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>
            <p><strong>These Terms of Use were last updated on December 8, 2020 and are deemed effective as amended as of this date.</strong></p>
            <h4>Governing Law and Jurisdiction:</h4>
            <p>These terms of Use are governed by and construed in accordance with the laws of this state (Kenya). You agree, as we do, to submit to the exclusive jurisdiction of the relevant courts.</p>
            <p><strong>Contact Us:</strong></p>
            <p>&nbsp;If you have any questions about these &ldquo;Terms of Use&rdquo; of our website, please contact us at <a href="http://www.spatialardhi.com">www.spatialardhi.com</a>.</p>
            <p>&nbsp;</p>
        </div>
    );
};

export default Terms;
