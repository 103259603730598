import React, { Component } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Search from '../components/Search';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-12 col-sm-12 col-lg-12"
                    >
                        <Navbar />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <Search />
                    </div>
                </div>
                <div className="row mt-5">
                    <div
                        style={{ background: "#FF00FF", color: "white" }}
                        className="col-md-12 col-sm-12 col-lg-12"
                    >
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;