import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import Iframe from "react-iframe";
import Product from "./Product";
import Map from "./Map"
import BlogPostReplies from "../components/blogpostreplies";

import Video from "./video"
import ProductsReviews from '../components/ProductsReviews';
import MerchantReviews from '../components/MerchantReviews';

export default function Marchant() {
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [avatars, setAvatar] = useState([]);
  const [merchant, setMerchant] = useState([]);

  let { id } = useParams();

  const marchanturl = `https://www.spatialardhi.com/estate/merchant/${id}/?format=json`;
  const avatarurl = `https://www.spatialardhi.com/estate/photos/${id}`;

  useEffect(() => {
    fetch(marchanturl)
      .then((res) => res.json())
      .then((result) => {
        setMerchant(result);
      });

    fetch(avatarurl)
      .then((res) => res.json())
      .then((result) => {
        setAvatar(result);
      });
  }, [avatarurl, marchanturl]);

  return (
    <div className="mt-2">
      <div className="row">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div className="merchant-details">
            <h1>
              Business Name : <br />
              <b>{merchant.business_name}</b>
            </h1>
            <h5>
              Business Slogan : <br />
              <b>{merchant.business_slogan}</b>
            </h5>
            <h5>
              Business Type : <br />
              <b>{merchant.business_type}</b>
            </h5>
            <h5>
              Physical location : <br />
              <b>{merchant.physical_location}</b>
            </h5>
            <h5>
              Available Goods : <br />
              <b>{merchant.available_goods}</b>
            </h5>
            <h5>
              URL : <br />
              <b>{merchant.url}</b>
            </h5>
          </div>
          <Iframe
            url={merchant.url}
            width="100%"
            height="450px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>
        <div className="col-sm-12 col-md-9 col-lg-9">
          <div>
            <Slider {...settings}>
              {avatars.map((avat) => {
                return (
                  <div key={avat.id}>
                    <img
                      width="100%"
                      height="500px"
                      src={avat.image}
                      alt={avat.merchant}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
          <div>
            <Product id={id} />
          </div>
          <div>
          <BlogPostReplies id={id} />
            {/* <Post id={id} /> */}
            <Video id={id} />
          </div>
          <div className="mt-5">
            <Map position={merchant} />
          </div>
        </div>
      </div>
    </div>
  );
}
