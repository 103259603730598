import { combineReducers } from "redux";

const initialState = {
    businessName: ""
}

const searchReducer = function (state = initialState, action) {
    switch (action.type) {
        case "CHANGE_SEARCH_BUSINESS_NAME":
            {
                return { ...state, businessName: action.payload };
            }
        default:
            return state;
    }
};

export default combineReducers({ searchReducer });
