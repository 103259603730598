import React, { Component } from 'react';
import { connect } from 'react-redux';

class searchForm extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.changeBusinessName(this.state.value);
    }

    render() {
        return (<div>
            <form>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                        >Business/Loc</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.value} onChange={this.handleChange}
                    />
                </div>
                {/* <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                        >
                            Location
                </span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.value} onChange={this.handleChange}
                    />
                </div> */}
                <input type="submit" value="Search" onClick={this.handleSubmit} className="btn btn-primary" />
            </form>
        </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeBusinessName: (businessName) => { dispatch({ type: 'CHANGE_SEARCH_BUSINESS_NAME', payload: businessName }) }
    }
};

export default connect(null, mapDispatchToProps)(searchForm);
