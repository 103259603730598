import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import useSWR from "swr";
import AddReview from './pages/AddReview';
import HomePage from './pages/HomePage';
import SingleMerchant from './pages/SingleMerchant';
import AddMerchant from './pages/AddMerchant';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';

const url = "https://www.spatialardhi.com/estate/merchant/?format=json";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function Routes() {
  const { data, error } = useSWR(url, fetcher);
  if (error) {
    return <div>Error</div>;
  }
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/merchant/:id">
          <SingleMerchant />
        </Route>
        <Route path="/addmerchant">
          <AddMerchant />
        </Route>
        <Route path="/addreview">
          <AddReview />
        </Route>
        <Route path="/terms">
          <TermsPage />
        </Route>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
      </Switch>
    </Router>
  );
}
