import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../img/logo.jpg";
const Footer = () => {
  return (
    <div className="container rosemeryFont">
      <div className="row mt-5 mb-5">
        <div className="col-sm-12 col-md-3 col-lg-3 ">
          <p>WE ARE DIFFERENT, KINDLE THE CHOICE, TRY US..</p>
          <img style={{ width: "100%" }} src={logo} alt="Something" />
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <p>ABOUT SPATIAL ARDHI</p>
          <p>
            Our team is genial, novel, qualified and advisedly small; we offer
            elegant, customizable advanced various property visualization that
            encourages you to create wise property purchase choices with our
            in-house solutions.
          </p>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <p>Home </p>
              <p>Buy</p>
              <p>Properties</p>
            </div>
            <div>
              <p>About us</p>
              <p>Contact us</p>
              <p><Link to="/terms">Terms</Link></p>
              <p><Link to="/privacy">Privacy</Link></p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <p>Follow us</p>
          <div className="social-icons">
            <FaInstagram size={20} />
            <FaFacebook size={20} />
            <FaTwitter size={20} />
            <FaLinkedin size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
