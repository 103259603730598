import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import MerchantImage from './MerchantImage';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return { busName: state.searchReducer.businessName }
};

function MerchantList(props) {

    useEffect(function () {
        loadMerchants();
    })

    useEffect(function () {
        console.log("val triggered"); loadMerchants();
    }, [props.busName]);

    const [merchants, setMerchants] = useState(null);
    const [limit, setLimit] = useState(3);
    const [businessName] = useState('');
    const [merchantCount, setMerchantCount] = useState(3);
    const [loadMoreVisible, setLoadMoreVisible] = useState('visible');

    const loadMerchants = () => {
        var merchantListUrl = '';

        if (props.busName.length > 0 && props.busName !== '') {
            merchantListUrl = 'https://www.spatialardhi.com/estate/merchant/?search=' + props.busName + '&format=json';
        }
        else if (businessName.length > 0) {
            merchantListUrl = 'https://www.spatialardhi.com/estate/merchant/?search=' + businessName + '&format=json';
        } else {
            merchantListUrl = 'https://www.spatialardhi.com/estate/merchant/?format=json';
        }

        fetch(merchantListUrl).then(response => response.json()).then(data => {
            setMerchantCount(data.length);
            setMerchants(data.slice(0, limit));
        });
    };


    const loadMore = () => {
        setLimit(limit + 3);
        loadMerchants();
        if (merchantCount > limit) { } else { setLoadMoreVisible('hidden'); }
    };

    if (!merchants || merchants.length === 0) return <></>;
    return (<>
        <div className="site-section site-section-sm bg-light">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-12">
                        <div className="site-section-title">
                            <h2>Merchants</h2>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    {merchants.map(merchants => {
                        return <div key={merchants.id} className="col-md-6 col-lg-4 mb-4">
                            <Link to={`/merchant/` + merchants.id} className="prop-entry d-block">
                                <MerchantImage image={'https://www.spatialardhi.com/estate/merchant-avatar/' + merchants.profile + '/?format=json'} />
                                <div className="prop-text">
                                    <div className="inner">
                                        <span className="price rounded">{merchants.business_type}</span>
                                        <h3 className="title">{merchants.business_name}</h3>
                                        <p className="location">{merchants.business_slogan}</p>
                                    </div>
                                    <div className="prop-more-info">
                                        <div className="inner d-flex">
                                            <div className="col">
                                                <span>Location:</span>
                                                <strong>{merchants.physical_location}</strong>
                                            </div>
                                            <div className="col">
                                                <span>Available Goods:</span>
                                                <strong>{merchants.available_goods}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                            ;
                    })}

                </div>
            </div>
        </div>
        <div>
            <Button variant="secondary" onClick={loadMore} style={{
                visibility: loadMoreVisible
            }}>Load More</Button>
        </div>      </>);
}
export default connect(mapStateToProps)(MerchantList);